.custom-accordion .p-accordion-header-link {
    padding: 1rem;
    font-size: 20px;
    text-align: left;
    text-decoration: none;
    /* Removes the underline */
    color: rgb(241, 241, 241);
    /* Sets the text color to white */
    font-weight: bold;
    /* Makes the font bold */
    border: none;
    /* Removes borders */
    background-color: rgb(49, 49, 49);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.p-accordion-tab{
    margin-bottom:1%
}

.p-accordion-content{
    font-size: 20px;
}

.qa-section{
    background: #080808;
    padding-bottom: 1%;
    margin-bottom: 5%;
}