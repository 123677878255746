
    .text-SectionImage{
        width: 50vw;

        @media (max-width: 480px) {
            font-size: medium !important;
            text-align: center;
            width: 80vw;
        }

    }