// TechnologyCard.scss
.technology-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
    background: #fff;
    height: 250px; // Adjust this value as needed to fit your content
  
    img {
        width: 100%;
        height: 80%;
        object-fit: contain;
        margin-bottom: 15px;
    }
  
    h5 {
      margin: 0;
      font-size: 16px; // Adjust as needed
      font-weight: 500;
    }
  }
  
  // Optionally, you might want to include these styles to hide the navigation and pagination under certain conditions or globally
  .swiper-pagination,
  .swiper-pagination-clickable,
  .swiper-pagination-bullets,
  .swiper-pagination-horizontal,
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
  