  .package-switch {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust the space between the switches */
  }
  
  .package-switch label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensure the label takes up the full width */
  }
  
  .package-switch label input {
    margin-left: 10px; /* Adjust the space between the label text and the switch */
  }
  