// Define variables
$bg-color-primary: srgb(0.03, 0.03, 0.03);
$text-color-primary: srgb(0.42, 0.77, 1);
$navbar-perspective: 500px;
$logo-max-height: 150px;
$logo-height-sm: 20px;
$logo-height-xs: 15px;
// Base font size is now dynamic based on screen size
$base-font-size-pc: 250px;
$base-font-size-mobile: 70px;
$base-font-size-mobile-20: 20px;

// Mixin for keyframes
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

// Define base styles using nested rules
.bg-index1 {
  background: rgb(11, 11, 11);
}

.bg-index2 {
  background: black;
}

.text-index1 {
  color: rgb(163, 202, 255);
}

.text-index2 {
  font-size: 45px;
  padding: 5%;
  color: #fff;
}

.text-index3 {
  font-size: 27px;
  text-align: right;
  font-weight: bold;
  color: white;
}

.text-index4 {
  font-size: 20px;
  text-align: right;
  color: #000;
  background: #e3e3e3;
  padding: 10px;
}

.text-index5 {
  font-size: 25px;
  color: #cacaca;
}

.div-index1 {
  padding-top: 5%;
  margin-bottom: 5%;
  background: #131313;
  padding-bottom: 5%;
}

.text-right {
  text-align: right;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  perspective: $navbar-perspective;
  .navbar-brand {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    img {
      max-height: $logo-max-height;
      width: auto;
      animation: spinY3D 19s infinite linear;
    }
  }
  .custom-navbar {
    background: black;
  }
  .nav-link {
    color: white !important;
    font-size: 25px;
    &:hover {
      color: rgb(163, 202, 255) !important;
    }
  }
  .nav, .navbar-collapse {
    display: flex;
    align-items: center;
  }
}

.col-md-8.text-right {
  text-align: right;
}

.doubleline {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-bottom: 1px solid #ffffff;
  box-shadow: 0 1px 0 #ffffff;
}

// Include keyframes using the mixin and content block
@include keyframes(spinY3D) {
  0% {
    transform: perspective($navbar-perspective) rotateY(0deg);
  }
  100% {
    transform: perspective($navbar-perspective) rotateY(360deg);
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .navbar-brand img {
    max-height: $logo-height-sm;
  }
  // Adjusting base font size for mobile
  .size-index1 {
    font-size: $base-font-size-mobile;
  }
}

// Default PC settings
.size-index1 {
  font-size: $base-font-size-pc;
}

@media (max-width: 480px) {
  .navbar-brand img {
    max-height: $logo-height-xs;
  }
  // Ensure this applies if you want even smaller font size for very small screens
  .size-index1 {
    font-size: $base-font-size-mobile;
  }

  .text-index2 {
    font-size: $base-font-size-mobile-20;
  }
}


