/* /var/www/website2024/dev.rampagesoft.com/my-app/src/style/Footer.scss */

.bg-primary-index {
    --bs-bg-opacity: 1;
    background-color: rgb(22 153 242) !important;
}
  
  .footer-content {
    position: relative; // This ensures the content is on top of the slits
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2; // Higher z-index to ensure content is above the slits
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    display: inline;
    margin-right: 20px;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  // Other styles...
