
.slide-up-enter {
  opacity: 0;
  transform: translateY(20px); // Slightly move up for the fade-in effect
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in; // Smooth transition for fade-in and move-up effects
}

// Adjust these classes to remove the fade-out effect
.slide-up-exit {
  opacity: 1;
}

.slide-up-exit-active {
  opacity: 0;
  transition: none; // No transition effect for hiding content
}

// -----

.slide-up-content {
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .col-md-6 {
    // Ensure each column takes full width on smaller screens
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .img-fluid {
    width: 100%; // Make image width responsive
    height: auto; // Maintain aspect ratio
    object-fit: cover; // Cover the area of the container without stretching

    // For browsers that do not support 'object-fit'
    @supports not (object-fit: cover) {
      width: auto; // Default to auto width for unsupported browsers
      max-width: 100%; // Ensure it does not overflow the container
      height: auto; // Maintain aspect ratio
    }
  }

  .card {
    width: 100%; // Ensure the card takes up the full width
    display: flex; // Use flexbox for centering
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically
    padding: 0; // Remove padding to ensure the image fits perfectly within the card
    .p-image {
      margin: 0;
      padding: 0;
    }
  }
}


// SlideUp.scss

.swiper {
  .swiper-wrapper {
    transition: transform 0.7s ease-in-out; // Smoothly animate the sliding transition
  }
}

.swiper-slide-index1 {
  opacity: 0; // Start with slides being transparent
  transition: opacity 0.7s ease; // Animate the opacity change for a smooth effect
  
  // When the slide becomes active, it gradually becomes fully opaque
  &.swiper-slide-active {
    opacity: 1;
  }
}

// Existing styles for slide-up-enter, slide-up-enter-active, etc. can remain unchanged
